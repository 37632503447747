import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import TermsOfUse from "../sections/TermsOfUse"

export default function aboutTermsOfUse() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms of Use</title>
      </Helmet>
      <Layout>
        <TermsOfUse />
      </Layout>
    </div>
  )
}
